import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

function NotFoundPage() {
    return (
        <Layout>
            <SEO
                title="404"
                description="U Save Services is one of the best service provider in whole USA. It has has stayed true to its original mission bring educated buyers and sellers together at the time they are ready to do business"
            />
            <div className="flex-grow-1 d-flex flex-column align-items-center p-5" style={{ backgroundColor: '#fefefe' }}>
                <h1 className="font-weight-bolder text-color-secondary" style={{ fontSize: 150 }}>Oops!</h1>
                <h4 className="mt-5">404 - PAGE NOT FOUND</h4>
            </div>
            <div className='text-center mb-5'>
                <p>The requested category  have been moved or not available.<br /> Please see all servcies <Link to="/all-services"><u>here</u></Link> </p>
            </div>
        </Layout>
    )
}

export default NotFoundPage
